import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/landing';

import CustomerFeatureBenefit from 'components/content-blocks/customer-feature-benefit';
import FeaturesGrid from 'components/content-blocks/features-grid';
import HighlightCards from 'components/content-blocks/highlight';
import Manager from 'components/content-blocks/manager';
import Reviews from 'components/content-blocks/reviews';
import Yoast from 'components/globals/yoast';
import { HeroLanding } from 'components/hero/landing';

import './styles/employee-scheduling.scss';

export default function EmployeeSchedulingLandingPage() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(
        filter: { slug: { eq: "employee-scheduling-landing-page" } }
      ) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                copy
                signup {
                  launch_modal_button_text
                  unique_id
                  legal_text
                }
                background_desktop_image {
                  source_url
                }
                background_mobile_image {
                  source_url
                }
              }
              default_landing_hero_partial {
                desktop_image {
                  source_url
                }
                mobile_image {
                  source_url
                }
                feature_text_1
                feature_text_2
                feature_text_3
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
              customer_feature_benefit {
                sub_title
                title
                copy
                customer {
                  business
                  name
                  photo_desktop {
                    source_url
                  }
                  photo_mobile {
                    source_url
                  }
                  since
                }
                stats {
                  value_1
                  descriptor_1
                  value_2
                  descriptor_2
                  value_3
                  descriptor_3
                }
              }
              cards_1 {
                header {
                  title
                  sub_title
                  copy
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                }
              }
              feature_manager_overview {
                copy
                title
                sub_title
                app_logos {
                  app_store {
                    source_url
                    alt_text
                  }
                  google_play {
                    source_url
                    alt_text
                  }
                }
                actions {
                  cards {
                    copy
                    phone_screen {
                      source_url
                      alt_text
                    }
                  }
                  mobile_card {
                    phone_screen {
                      source_url
                      alt_text
                    }
                  }
                  title
                }
                face_icons {
                  center {
                    source_url
                    alt_text
                  }
                  right {
                    source_url
                    alt_text
                  }
                  left {
                    source_url
                    alt_text
                  }
                }
                phone_case_background {
                  left_and_right {
                    source_url
                    alt_text
                  }
                  center {
                    source_url
                    alt_text
                  }
                }
              }
            }
          }
        }
      }
      reviews: allWordpressPage(filter: { slug: { eq: "home" } }) {
        edges {
          node {
            acf {
              reviews_section {
                title
                sub_title
                copy
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const featuresGrid = pageContext.features_grid;
  const cards = pageContext.cards_1;
  const customerCard = pageContext.customer_feature_benefit;
  const reviews = data.reviews.edges[0].node.acf.reviews_section;
  const featureManagerOverview = pageContext.feature_manager_overview[0];

  return (
    <Layout legalText={ hero.signup.legal_text }>
      <div className="lp-employee-scheduling-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
        <HeroLanding
          { ...hero }
          defaultLandingHero={ pageContext.default_landing_hero_partial }
          section="featuresSchedulingHero"
        />
        <HighlightCards { ...cards } hasTitle hasCopy hasImage />
        <FeaturesGrid { ...featuresGrid[0] } />
        <CustomerFeatureBenefit { ...customerCard[0] } />
        <Manager { ...featureManagerOverview } />
        <Reviews { ...reviews } />
      </div>
    </Layout>
  );
}
